import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

export class Header extends Component {
  render() {
    return (
      <Fade>
        <header id="header">
          <div className="video-parent">
          <img src='img/logo.png' className="logo-full"/>
          <div className="full-overlay"></div>
          <MobileView>
               <div className="img-fallback"></div>
            </MobileView>
            <BrowserView>
          <div className="video-container">
           
            <video autoPlay loop muted preload="metadata" poster="img/portfolio/01-large.jpg">
              <source
                src="https://3rdaveevents.s3-us-west-1.amazonaws.com/hero-vid.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
           
            
          </div>
          </BrowserView>
          </div>
        </header>
      </Fade>
    );
  }
}

export default Header;
