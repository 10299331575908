import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Tilt from 'react-tilt'

export class Services extends Component {
  render() {
    return (
      <Fade bottom duration={1000} distance="20px">
        <div id="services" className="text-center">
          <div className="container">
            <div className="section-title">
              <h2>Our Services</h2>
              
            </div>
            <div className="row">
              {this.props.data
                ? this.props.data.map((d, i) => (
                    <div key={`${d.name}-${i}`} className="col-md-6">
                      {" "}
                      {/* <Tilt className="Tilt" options={{ max : 25 }} style={{ height: 120, width: 120 }} > */}
                        {/* <div className="Tilt-inner">
                        <img src={d.img}/>
                        </div> */}
                      
                      <i className={d.icon}></i>
                      {/* </Tilt> */}
                      <div className="service-desc">
                        <h3>{d.name}</h3>
                        
                        <div className="row">
                          <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-6">
                    <ul>
                      {
                        d.services1.map((d, i) => (
                            <li key={`${d}-${i}`} style={{textAlign: "right"}}>{d}</li>
                          ))
                        }
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-6">
                    <ul>
                      {d.services2.map((d, i) => (
                            <li key={`${d}-${i}`} style={{textAlign: "left"}}>{d}</li>
                          ))
                       }
                    </ul>
                  </div>
                </div> 
                </div>
                      </div>
                    </div>
                  ))
                : "loading"}
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default Services;
