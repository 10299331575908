import React, { Component } from "react";
import Slide from "react-reveal/Slide";

export class about extends Component {
  render() {
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <Slide left>
              <div className="col-xs-12 col-md-6">
                {" "}
                <img
                  src="img/about.jpg"
                  className="img-responsive"
                  alt=""
                />{" "}
              </div>
            </Slide>
            <Slide right>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <br></br>
                <p style={{whiteSpace: "pre-line"}}>
                  {this.props.data ? this.props.data.paragraph : "loading..."}
                </p>
                {/* <h3>Why Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data
                        ? this.props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data
                        ? this.props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>  */}
              </div>
            </div>
            </Slide>
          </div>
        </div>
      </div>
    );
  }
}

export default about;
