import React, { Component } from "react";
import Fade from "react-reveal/Fade";

export class Gallery extends Component {
  render() {
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <Fade bottom>
            <div className="section-title">
              <h2 className="black">Gallery</h2>
              
            </div>

            <div className="row">
              <div className="portfolio-items">
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <div
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                      >
                        {/* <div className="hover-text">
                          <h4>Lorem Ipsum</h4>
                        </div> */}
                        <img
                          src="img/portfolio/01-small.jpg"
                          className="img-responsive"
                          alt="Project Title"
                        />{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <div
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                      >
                        {/* <div className="hover-text">
                          <h4>Adipiscing Elit</h4>
                        </div> */}
                        <img
                          src="img/portfolio/02-small.jpg"
                          className="img-responsive"
                          alt="Project Title"
                        />{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <div
                        href="img/portfolio/03-large.jpg"
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                      >
                        {/* <div className="hover-text">
                          <h4>Lorem Ipsum</h4>
                        </div> */}
                        <img
                          src="img/portfolio/03-small.jpg"
                          className="img-responsive"
                          alt="Project Title"
                        />{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <div
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                      >
                        {/* <div className="hover-text">
                          <h4>Lorem Ipsum</h4>
                        </div> */}
                        <img
                          src="img/portfolio/04-small.jpg"
                          className="img-responsive"
                          alt="Project Title"
                        />{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <div
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                      >
                        {/* <div className="hover-text">
                          <h4>Adipiscing Elit</h4>
                        </div> */}
                        <img
                          src="img/portfolio/05-small.jpg"
                          className="img-responsive"
                          alt="Project Title"
                        />{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <div
                        href="img/portfolio/06-large.jpg"
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                      >
                        {/* <div className="hover-text">
                          <h4>Dolor Sit</h4>
                        </div> */}
                        <img
                          src="img/portfolio/06-small.jpg"
                          className="img-responsive"
                          alt="Project Title"
                        />{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <div
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                      >
                        {/* <div className="hover-text">
                          <h4>Dolor Sit</h4>
                        </div> */}
                        <img
                          src="img/portfolio/07-small.jpg"
                          className="img-responsive"
                          alt="Project Title"
                        />{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <div
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                      >
                        {/* <div className="hover-text">
                          <h4>Lorem Ipsum</h4>
                        </div> */}
                        <img
                          src="img/portfolio/08-small.jpg"
                          className="img-responsive"
                          alt="Project Title"
                        />{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <div
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                      >
                        {/* <div className="hover-text">
                          <h4>Adipiscing Elit</h4>
                        </div> */}
                        <img
                          src="img/portfolio/09-small.jpg"
                          className="img-responsive"
                          alt="Project Title"
                        />{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    );
  }
}

export default Gallery;
